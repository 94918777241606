import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['select']

  change_role() {
    let url = this.data.get('url')
    var formData = new FormData();
    formData.append("role", this.selectTarget.value);

    fetch(url, {
      method: 'PUT',
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      },
      body: formData
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}