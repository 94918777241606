import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="jump-to-video"
export default class extends Controller {
  static outlets = ["video"]
  static values = { time: Number }

  jump() {
    if (this.hasVideoOutlet) {
      this.videoOutlet.videoPlayer.currentTime(this.timeValue)
      this.videoOutlet.videoPlayer.play()
    }
  }
}
