import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["shape"]
  connect() {
    this.element.style.backgroundColor = this.data.get('background')
    this.shapeTarget.style.setProperty('color', this.data.get('background'), 'important')
    this.element.style.cursor = "pointer"
  }

  showButton() {
    this.element.style.backgroundColor = '#f7f7f7'
    this.shapeTarget.style.setProperty('color', '#f7f7f7', 'important')
  }

  hideButton() {
    this.element.style.backgroundColor = this.data.get('background')
    this.shapeTarget.style.setProperty('color', this.data.get('background'), 'important')
  }

  followLink() {
    window.location.href = this.data.get('url')
  }
}