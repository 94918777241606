import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = { session: String }
  connect() {
    var stripe_public_key = document.querySelector('meta[name="stripe-public-key"]').getAttribute('content');
    var stripe = Stripe(stripe_public_key);
    stripe.redirectToCheckout({
      sessionId: this.sessionValue
    }).then(function (result) {
    });
  }
}
