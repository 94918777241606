import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="unsubscribe-all"
export default class extends Controller {
  submit() {
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.element.submit()
  }
}
