import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="update-history"
export default class extends Controller {
  connect() {
    this.element.setAttribute("data-action", "click->update-history#click")
  }

  click() {
    history.replaceState(history.state, "", this.element.href)
  }
}
