import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['input', 'button']
  initialize() {}
  connect() {
    if (!document.queryCommandSupported("copy")) {
      console.log('Clipboard copying not supported')
    }
  }
  disconnect() {}

  copy() {
    this.inputTarget.select()
    document.execCommand("copy")
    this.buttonTarget.innerHTML = "Copied!"
  }
}