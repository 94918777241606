import { Controller } from "@hotwired/stimulus"
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

// import contribQualityLevels from 'videojs-contrib-quality-levels'
import qualitySelector from 'videojs-hls-quality-selector'
import "videojs-seek-buttons"
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css'

export default class extends Controller {
  static outlets = ["current-times-updater"]
  static targets = ["player"]
  static values = { time: 0 }

  initializePlayer() {
    require('videojs-contrib-quality-levels')

    videojs.registerPlugin('hlsQualitySelector', qualitySelector)

    videojs.options.vhs.overrideNative = true
    videojs.options.controls = true,
    videojs.options.nativeControlsForTouch = false
    videojs.options.playsinline = true
    videojs.options.vhs.enableLowInitialPlaylist = false
    videojs.options.html5.nativeAudioTracks = false
    videojs.options.html5.nativeTextTracks = false
  }

  connect() {
    this.episode = this.playerTarget.getAttribute('data-episode')
    this.episode_id = this.playerTarget.getAttribute('data-episode-id')
    this.initializePlayer()
    let that = this
    this.videoPlayer = videojs(this.playerTarget, {
      playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
      poster: this.data.get('poster'),
      fluid: true,
      plugins: {
        seekButtons: {
          forward: 10,
          back: 10
        }
      }
    })

    this.videoPlayer.on("loadedmetadata", function() {
      let markers = JSON.parse(that.element.getAttribute("data-chapters"))
      if (markers.length == 0) {
        return
      }
      let total = this.duration()
      let p = this.controlBar.progressControl.children_[0].el_

      for (var i = 0; i < markers.length; i++) {
        let time = parseFloat(markers[i].time)

        let left = (time / total * 100) + '%'
        let el = document.createElement("div")
        el.setAttribute("class", "vjs-marker")
        el.setAttribute("style", "left:" + left)
        el.setAttribute("data-time", time)
        let span = document.createElement("span")
        span.textContent = markers[i].label
        el.appendChild(span)

        el.addEventListener("click", function () {
          that.videoPlayer.currentTime(this.getAttribute('data-time'))
        })
        p.append(el)
      }
    })

    this.videoPlayer.ready(function () {
      if (this.canPlayType('application/x-mpegURL') == "maybe") {
        this.currentTime(that.timeValue)
        that.currentTime = that.videoPlayer.currentTime()
        that.interval = setInterval(() => { that.timer() }, 10000)
        that.updateProgressBarInterval = setInterval(() => { that.updateProgressBar() }, 1000)
      } else {
        console.log("Your browser, network or extensions may be preventing video to play.")
      }

      this.hlsQualitySelector({ displayCurrentQuality: true, placementIndex: 11 })

      this.on('ended', function () {
        fetch(`/stickers/watched_videos`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ episode: that.episode })
        })

        fetch(`/episodes/${that.episode_id}/episodes/create`, {})
      })
    })
  }

  timer() {
    this.lastTime = this.currentTime
    this.currentTime = this.videoPlayer.currentTime()
    if (this.lastTime == this.currentTime) return true

    fetch(`/current_times`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ episode: this.episode, current_time: this.currentTime })
    })

  }

  skipBack() {
    let currentTime = this.videoPlayer.currentTime()
    this.videoPlayer.currentTime(currentTime - 10)
  }

  skipForward() {
    let currentTime = this.videoPlayer.currentTime()
    this.videoPlayer.currentTime(currentTime + 10)
  }

  playPause() {
    try {
      if (this.videoPlayer.paused()) {
        this.videoPlayer.play()
      } else {
        this.videoPlayer.pause()
      }
    } catch(e) {
    }
  }

  updateProgressBar() {
    if (this.hasCurrentTimesUpdaterOutlet && this.currentTimesUpdaterOutletElement) {
      let currentTime = this.videoPlayer.currentTime()
      let totalTime = this.videoPlayer.duration()
      let percentage = (currentTime / totalTime) * 100
      let style = `width: ${percentage}%`
      this.currentTimesUpdaterOutletElement.style = style
      this.currentTimesUpdaterOutletElement.innerText = `${parseInt(percentage)}%`
    }
  }

  disconnect() {
    clearInterval(this.interval)
    clearInterval(this.updateProgressBarInterval)
    this.videoPlayer.dispose()
  }
}
