import { Controller } from "@hotwired/stimulus"
import hljs from 'highlight.js/lib/core'
import 'highlight.js/styles/base16/gruvbox-dark-medium.css'

hljs.registerLanguage('ruby', require('highlight.js/lib/languages/ruby'))
hljs.registerLanguage('erb', require('highlight.js/lib/languages/erb'))
hljs.registerLanguage('bash', require('highlight.js/lib/languages/bash'))
hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript'))
hljs.registerLanguage('css', require('highlight.js/lib/languages/css'))
hljs.registerLanguage('plaintext', require('highlight.js/lib/languages/plaintext'))
hljs.configure({
  ignoreUnescapedHTML: 'true'
});

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('pre').forEach((block) => {
      block.setAttribute("data-controller", "clipboard-code")
      block.setAttribute("data-clipboard-code-content-value", block.innerText)
      hljs.highlightElement(block);
    });
  }
}
