import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["episode"]

  connect() {}

  moveUp() {
    let array = this.episodeTargets.map( x => x.dataset.id )
    let item_id = event.target.dataset.id
    let index = array.findIndex(id => id == item_id)
    let new_array = this.move(array, index, index - 1)
    let url = this.data.get('url')
    let token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let data = { positions: new_array }
    fetch(url, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: {
        "X-CSRF-Token": token,
        "Accept": "application/json",
        "Content-type": "application/json"
      },
      body: JSON.stringify(data)
    })
  }

  move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }


}