import { Controller } from "@hotwired/stimulus"
import Tagify from '@yaireo/tagify'
import "@yaireo/tagify/dist/tagify.css"
export default class extends Controller {
  static values = { options: Array }
  initialize() {
    new Tagify(this.element, {
      whitelist: this.optionsValue,
      dropdown: {
        classname: "color-blue",
        enabled: 0,
        maxItems: 5,
        position: "text",
        closeOnSelect: false,
        highlightFirst: true
      }
    });
  }
}
