import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['input', 'check']
  initialize() {}

  connect() {}

  disconnect() {}

  update_view(){
    let data = this.inputTarget.selectedOptions[0].value
    let new_users_only = this.checkTarget.checked
    let url = this.data.get('url')
    let params = new URLSearchParams()
    params.append('users', data)
    params.append('new_users_only', new_users_only)
    this.post_to_url(url, params)
  }

  post_to_url(path, params) {
    fetch(`${path}?${params}`, {
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  params() {

    return params
  }
}