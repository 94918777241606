import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://c2c6133fb3f2adbb5ae44ad1692a29f7@sentry.vaas.me/2",
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

function setUserInSentry() {
  var email = document.querySelector('meta[name="email"]')?.content;
  var username = document.querySelector('meta[name="username"]')?.content;
  if (email) {
    Sentry.setUser({ email: email });
  }
  if (username) {
    Sentry.setUser({ username: username });
  }
}

document.addEventListener('turbo:load', setUserInSentry);
setUserInSentry();
