import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["label", "input"]
  initialize() {
  }

  connect() {
    this.labelTarget.innerText = `${this.inputTarget.value} rows per page`
  }

  change(event) {
    this.labelTarget.innerText = `${event.target.value} rows per page`
  }

  disconnect() {}
}