import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video-chapter-grabber"
export default class extends Controller {
  static outlets = ["video"]
  static targets = ["input"]

  getTime() {
    if (this.hasVideoOutlet) {
      let time = this.videoOutlet.videoPlayer.currentTime()
      this.inputTarget.value = time
    }
  }
}
