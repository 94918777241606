import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard-code"
export default class extends Controller {
  static values = { content: String }

  initialize() {
    this.element.setAttribute("style", "position: relative;")
    this.copy_button = document.createElement("button")
    this.copy_button.setAttribute("data-action", "click->clipboard-code#copy")
    this.copy_button.innerHTML = "Copy"
    this.copy_button.setAttribute("class", "badge bg-light text-dark p-1")
    this.copy_button.setAttribute("style", "position: absolute; z-index: 9999; top: 10px; right: 10px;")
    this.element.appendChild(this.copy_button)
  }

  copy() {

    navigator.clipboard.writeText(this.contentValue)
    this.copy_button.innerHTML = "Copied"
  }
}
