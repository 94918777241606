import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['target']
  connect() {
    var Sharer = function (elem) {
      this.elem = elem;
    };

    Sharer.init = function () {
      var elems = document.querySelectorAll('[data-sharer]'),
        i,
        l = elems.length;

      for (i = 0; i < l; i++) {
        elems[i].addEventListener('click', Sharer.add);
      }
    };

    Sharer.add = function (elem) {
      var target = elem.currentTarget || elem.srcElement;
      var sharer = new Sharer(target);
      sharer.share();
    };

    Sharer.prototype = {
      constructor: Sharer,
      getValue: function (attr) {
        var val = this.elem.getAttribute('data-' + attr);
        if (val && attr === 'hashtag') {
          if (!val.startsWith('#')) {
            val = '#' + val;
          }
        }
        return val;
      },

      share: function () {
        var sharer = this.getValue('sharer').toLowerCase(),
          sharers = {
            facebook: {
              shareUrl: 'https://www.facebook.com/sharer/sharer.php',
              params: {
                u: this.getValue('url'),
                hashtag: this.getValue('hashtag'),
              },
            },
            linkedin: {
              shareUrl: 'https://www.linkedin.com/shareArticle',
              params: {
                url: this.getValue('url'),
                mini: true,
              },
            },
            twitter: {
              shareUrl: 'https://twitter.com/intent/tweet/',
              params: {
                text: this.getValue('title'),
                url: this.getValue('url'),
                hashtags: this.getValue('hashtags'),
                via: this.getValue('via'),
              },
            },
            pinterest: {
              shareUrl: 'https://www.pinterest.com/pin/create/button/',
              params: {
                url: this.getValue('url'),
                media: this.getValue('image'),
                description: this.getValue('description'),
              },
            },
            reddit: {
              shareUrl: 'https://www.reddit.com/submit',
              params: { url: this.getValue('url') },
            },

            flipboard: {
              shareUrl: 'https://share.flipboard.com/bookmarklet/popout',
              params: {
                v: 2,
                title: this.getValue('title'),
                url: this.getValue('url'),
                t: Date.now(),
              },
            },
          },
          s = sharers[sharer];

        if (s) {
          s.width = this.getValue('width');
          s.height = this.getValue('height');
        }
        return s !== undefined ? this.urlSharer(s) : false;
      },
      urlSharer: function (sharer) {
        var p = sharer.params || {},
          keys = Object.keys(p),
          i,
          str = keys.length > 0 ? '?' : '';
        for (i = 0; i < keys.length; i++) {
          if (str !== '?') {
            str += '&';
          }
          if (p[keys[i]]) {
            str += keys[i] + '=' + encodeURIComponent(p[keys[i]]);
          }
        }
        sharer.shareUrl += str;

        if (!sharer.isLink) {
          var popWidth = sharer.width || 600,
            popHeight = sharer.height || 480,
            left = window.innerWidth / 2 - popWidth / 2 + window.screenX,
            top = window.innerHeight / 2 - popHeight / 2 + window.screenY,
            popParams = 'scrollbars=no, width=' + popWidth + ', height=' + popHeight + ', top=' + top + ', left=' + left,
            newWindow = window.open(sharer.shareUrl, '', popParams);

          if (window.focus) {
            newWindow.focus();
          }
        } else {
          window.location.href = sharer.shareUrl;
        }
      },
    };
    Sharer.init()
  }
}